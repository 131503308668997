import React from 'react'
import styled from 'styled-components'
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts'
import SVG from 'react-inlinesvg'
import { formatChartDataFromNewOldStats } from '../../utils/helpers/chart'

const chartHeight = 120

const Container = styled.div.attrs((props) => ({
  className: 'panel',
}))`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;

  h5 {
    font-size: 12px;
    font-weight: 200;
  }

  .total {
    font-size: 26px;
    font-weight: 500;
    white-space: nowrap;

    > span {
      font-size: 12px;
      font-weight: 500;
      color: var(--text-secondary);
    }

    &.percentage > span {
      vertical-align: super;
      font-size: 16px;
    }
  }

  .total-container {
    position: relative;
  }

  .trend {
    position: absolute;
    top: 32px;
    right: 0;
    display: inline-block;
    padding: 2px 6px;
    margin-left: 12px;
    border-radius: 3px;
    font-size: 9px;

    svg {
      width: 10px;
      height: 10px;
      margin-right: 4px;
      vertical-align: middle;
    }

    span {
      vertical-align: middle;
    }

    &.positive {
      color: #32c417;
      background: rgba(29, 190, 0, 0.1);
    }

    &.negative {
      color: #cd3a3a;
      background: rgba(199, 38, 38, 0.1);
    }
  }

  .graph-hint {
    margin-top: 4px;
    font-size: 12px;
    font-weight: 600;
    text-align: right;
    color: var(--text-secondary);
    white-space: nowrap;

    > span {
      display: inline-block;

      &:before {
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        margin-right: 4px;
        border-radius: 4px;
      }
    }

    > span + span {
      margin-left: 16px;
    }

    .graph-hint-previous {
      &:before {
        background: #bababa;
      }
    }

    .graph-hint-current {
      &:before {
        background: #17a3c2;
      }
    }
  }

  .no-graph {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    height: ${chartHeight}px;
    color: var(--text-secondary);
    font-size: 13px;
    font-weight: 600;
    text-align: center;
  }
`

const formatMoney = (value, currency = '') => {
  value = Number(value)
  if (value > 1000000) {
    return `${currency} ${(value / 1000000).toFixed(1)}M`
  } else if (value > 1000) {
    return `${currency} ${(value / 1000).toFixed(1)}K`
  } else {
    return `${currency} ${value.toFixed(2)}`
  }
}

const calculateTrend = (type, before, after, count) => {
  if (before > 0 && after > 0) {
    if (type === 'percentage') {
      return (after * 100) / (before * 100)
    } else {
      return (after / before) * 100 - 100
    }
  } else if (before === 0 && after === 0) {
    return 0
  } else if (before === 0) {
    return 100
  } else if (after === 0) {
    return -100
  } else {
    return 0
  }
}

const countTotal = (stats, type, field) =>
  stats.reduce((sum, stat) => {
    switch (type) {
      case 'float':
      case 'money':
      case 'percentage':
        return (sum += parseFloat(stat[field]) || 0)
      case 'integer':
      case 'count':
        return (sum += parseInt(stat[field]) || 0)
      default:
        return sum
    }
  }, 0)

export default function OverviewGraph(props) {
  const {
    title,
    type,
    field,
    addType,
    addField,
    subType,
    subField,
    mulType,
    mulField,
    divType,
    divField,
    graphField,
    trend,
    stats,
    oldStats,
    startDate,
    endDate,
  } = props

  const oldTitle = `Previous ${title.toLowerCase()}`

  let total = countTotal(stats, type, field)
  let oldTotal = countTotal(oldStats, type, field)
  if (total && divField === 'length') {
    total /= stats.length || 1
    oldTotal /= oldStats.length || 1
  } else if (total && divField) {
    const avgCount = countTotal(stats, divType, divField)
    const oldAvgCount = countTotal(oldStats, divType, divField)

    if (avgCount) {
      total /= avgCount
    } else {
      total = 0
    }
    if (oldAvgCount) {
      oldTotal /= oldAvgCount
    } else {
      oldTotal = 0
    }
  }
  if (total && mulField) {
    const mulCount = countTotal(stats, mulType, mulField)
    const oldMulCount = countTotal(oldStats, mulType, mulField)

    if (mulCount) {
      total /= mulCount
    } else {
      total = 0
    }
    if (oldMulCount) {
      oldTotal /= oldMulCount
    } else {
      oldTotal = 0
    }
  }
  if (addField) {
    const addCount = countTotal(stats, addType, addField)
    const oldAddCount = countTotal(oldStats, addType, addField)

    total -= addCount || 0
    oldTotal -= oldAddCount || 0
  }
  if (subField) {
    const subCount = countTotal(stats, subType, subField)
    const oldSubCount = countTotal(oldStats, subType, subField)

    total -= subCount || 0
    oldTotal -= oldSubCount || 0
  }

  const trendValue = calculateTrend(type, oldTotal, total, stats.length)

  const currency = stats[0]?.currency || 'DKK'

  const data = formatChartDataFromNewOldStats(
    stats,
    oldStats,
    { [title]: graphField },
    { [oldTitle]: graphField },
    startDate,
    endDate
  )

  return (
    <Container>
      <div className="total-container">
        <h5>{title}</h5>
        <div className={`total ${type}`}>
          {type === 'money' && (
            <>
              {formatMoney(total)} <span>{currency}</span>
              {trend && <Trend value={trendValue} />}
            </>
          )}
          {type === 'count' && (
            <>
              {total}
              {trend && <Trend value={trendValue} />}
            </>
          )}
          {type === 'float' && (
            <>
              {total.toFixed(2)}
              {trend && <Trend value={trendValue} />}
            </>
          )}
          {type === 'percentage' && (
            <>
              {(total * 100).toFixed(2)} <span>%</span>
              {trend && <Trend value={trendValue} />}
            </>
          )}
          {type === 'none' && <>{'-'}</>}
        </div>
      </div>
      <div>
        {type === 'none' && <div className="no-graph">No data</div>}
        {type !== 'none' && (
          <>
            <ResponsiveContainer width="100%" height={chartHeight}>
              <LineChart data={data} margin={{ top: 30, bottom: 10 }}>
                <CartesianGrid horizontal={false} stroke="#efefef" />
                <Line
                  type="monotone"
                  dataKey={oldTitle}
                  stroke="#bababa"
                  strokeWidth={2}
                  dot={false}
                  activeDot={false}
                />
                <Line
                  type="monotone"
                  dataKey={title}
                  stroke="#17a3c2"
                  strokeWidth={2}
                  dot={false}
                  activeDot={false}
                />
                <Tooltip
                  formatter={(value) => {
                    switch (type) {
                      case 'money':
                        return Number(value).toFixed(3).replace(/0$/, '')
                      case 'count':
                        return Number(value)
                      case 'percentage':
                        return Number(value).toFixed(2) + '%'
                      default:
                        return Number(value)
                    }
                  }}
                  labelFormatter={(index) => data[index]?.label || ''}
                />
              </LineChart>
            </ResponsiveContainer>
            <div className="graph-hint">
              <span className="graph-hint-previous">Previous</span>
              <span className="graph-hint-current">Current</span>
            </div>
          </>
        )}
      </div>
    </Container>
  )
}

const Trend = (props) => {
  const { value } = props

  const shownValue = value.toFixed(2)

  if (value >= 0) {
    return (
      <div className="trend positive">
        <SVG src="/images/icons/trend-arrow-up.svg" />
        <span>{shownValue}%</span>
      </div>
    )
  } else {
    return (
      <div className="trend negative">
        <SVG src="/images/icons/trend-arrow-down.svg" />
        <span>{shownValue}%</span>
      </div>
    )
  }
}
